<template>
  <div class="w-full container mx-auto flex-1 flex flex-col items-center justify-center py-20">
    <h2 class="font-bold text-4xl">Finishing Authentication</h2>

    <div v-if="working" class="mt-24 flex items-center justify-center space-x-16">
      <div>
        <h2 class="capitalize text-5xl font-medium leading-none font-poppins">
          {{ provider }}
        </h2>
      </div>
      <div>
        <span class="h-8 w-8 loader"></span>
      </div>
      <div>
        <img class="h-12" src="/images/guestio_logo.png" alt="Guestio">
      </div>
    </div>

    <div v-if="! working && error" class="mt-24 sm:max-w-xl w-full">
      <div class="rounded-md bg-red-100 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm leading-5 font-medium text-red-800">
              An error occured
            </h3>
            <div class="mt-4 text-sm leading-5 text-red-700">
              <p>{{ error }}</p>
              <p class="mt-2">Please try again or contact support.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  import { setCSRFCookie } from '@/api/auth'
  import api from '@/api'
  import timezones from '@/mixins/timezones'
  import moment from 'moment'
  export default {
    name: "SocialiteLogin",

    mixins: [timezones],

    data() {
      return {
        working: true,
        error: null,
        userTimezone: null,
      }
    },

    computed: {
      provider() {
        return this.$route.params.provider
      },

      code() {
        return this.$route.query.code
      },

      errorMessage() {
        return this.$route.query.error
      }
    },

    methods: {
      guessTimezone() {
        let zone = moment().utcOffset()

        if (moment().isDST()) {
          zone -= 60
        }

        let timezone = this.timezones.find(timezone => timezone.timezone == Intl.DateTimeFormat().resolvedOptions().timeZone);

        if (! timezone) {
          timezone = this.timezones.find(timezone => timezone.offset == (zone / 60));
        }

        if (timezone) {
          this.userTimezone = timezone.timezone
        }
      },

      async finishLogin() {
        await setCSRFCookie()

        api.post(`/auth/login/${this.provider}/callback`, {
          ...this.$route.query,
          timezone: this.userTimezone,
          device: 'spa',
        }).then(({ data }) => {
          this.$store.dispatch('auth/socialLogin', data.data.user)
          Cookies.set('login-confirmed', true, {
            expires: new Date(new Date().getTime() + 5 * 60 * 1000), // 5 minutes
            sameSite: 'Lax',
          })
          if (data.data.redirect_to) {
            this.$router.push({name: data.data.redirect_to})
          }
        }).catch(error => {
          this.working = false
          if (error.response && error.response.data) {
            this.error = error.response.data.message
            return
          }

          this.error = 'Please try again or contact support.'
        })
      }
    },

    created() {
      if (this.errorMessage) {
        this.working = false
        this.error = this.errorMessage
        return
      }

      this.guessTimezone()
      this.finishLogin()
    },
  }
</script>
<style scoped>
  .loader {
  border: 5px solid;
  border-color: #C130EE transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

